<template>
  <div class="container">
    <div class="">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  name: "Onboarding"
};
</script>
