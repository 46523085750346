<template>
  <div class="tab">
    <slot />
  </div>
</template>

<script>
export default {
  name: "Tab"
};
</script>
