<template>
  <div class="loader">
    <loader-icon />
  </div>
</template>

<script>
// icons
import LoaderIcon from "./icons/Loader";

export default {
  name: "Loader",
  components: {
    LoaderIcon
  }
};
</script>
